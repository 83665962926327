import axios from "axios";
import { useEffect, useState } from "react";
import config from "./Utils/config";
import Loader from "./Utils/loader";
import '../css/ManageCompany.css';
import showToast from "./Utils/showToast";
import ConfirmationModal from "./Utils/ConfirmationModal";
import convertISOStringToMonthDay from "./Utils/CustomFormatter";

const FrenchiesBranches = () => {
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentBranches, setCurrentBranches] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [branchesDelete, setBranchesDelete] = useState(null);
    const [branches, setBranches] = useState([]); // Ensure this is initialized as an empty array
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [branchesForm, setBranchesForm] = useState({
        branchName: '',
        email: '',
        phone: '',
        contactPerson: '',
    });

    useEffect(() => {
        const fetchBranchesList = async () => {
            try {
                const apiUrl = `${config.apiBaseUrl}Frenchies/BranchListById`;
                const token = localStorage.getItem('token');
                const response = await axios.get(apiUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (Array.isArray(response.data)) {
                    setBranches(response.data); 
                } else if (response.data && response.data.branchId) {
                    setBranches([response.data]); 
                } else {
                    setBranches([]); 
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(error);
                showToast({ type: 'error', message: 'Error fetching branches!' });
            }
        };

        fetchBranchesList();
    }, []);

    if (loading) {
        return <Loader isLoading={loading} />;
    }

    if (error) {
        return <div>Error fetching data: {error.message}</div>;
    }

    const handleEdit = (branch) => {
        setCurrentBranches(branch);
        setBranchesForm({
            branchName: branch.branchName,
            email: branch.email,
            phone: branch.phone,
            contactPerson: branch.contactPerson,
        });
        setIsEditing(true);
        setIsAddModalOpen(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBranchesForm({
            ...branchesForm,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
           if (isEditing) {
                // Edit branches
                const id = currentBranches.branchId;
                const response = await axios.put(`${config.apiBaseUrl}Frenchies/BranchUpdate/${id}`, {
                    ...branchesForm,
                    branchId: currentBranches.branchId,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                showToast({ type: 'success', message: 'Branch updated successfully!' });
                window.location.reload();
            }
        } catch (error) {
            showToast({ type: 'error', message: 'Error submitting data!' });
        } finally {
            setIsAdding(false);
            setIsEditing(false);
            setBranchesForm({
                branchName: '',
                email: '',
                phone: '',
                contactPerson: '',
            });
            setIsAddModalOpen(false);
        }
    };
    return (
        <div className="company-table">
            <div className="d-flex justify-content-between">
                <h2>Branches List</h2>
            </div>

            {/* Add/Edit Form in Modal */}
            {isAddModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Edit Branch</h3>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label>Branch Name</label>
                                <input
                                    type="text"
                                    name="branchName"
                                    value={branchesForm.branchName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={branchesForm.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Phone</label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={branchesForm.phone}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label>Contact Person</label>
                                <input
                                    type="text"
                                    name="contactPerson"
                                    value={branchesForm.contactPerson}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary">Update</button>
                                <button type="button" className="btn btn-secondary" onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Branch Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Contact Person</th>
                        <th>Created On</th>
                        <th>Last Modified</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(branches) && branches.length > 0 ? (
                        branches.map((branch) => (
                            <tr key={branch.branchId}>
                                <td>{branch.branchName}</td>
                                <td>{branch.email}</td>
                                <td>{branch.phone}</td>
                                <td>{branch.contactPerson}</td>
                                <td>{convertISOStringToMonthDay(branch.createdDate)}</td>
                                <td>{convertISOStringToMonthDay(branch.lastModifiedDate)}</td>
                                <td>
                                    <button className="action-button edit-button" onClick={() => handleEdit(branch)} title="Edit branch">
                                        <i className="fa fa-edit"></i>
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr><td colSpan="8">No branches available</td></tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default FrenchiesBranches;
